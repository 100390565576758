import React from 'react';

import { FiInstagram, FiMail } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='footer' id='footer'>
      <ul className='social-list'>
        <li className='social-list__item'>
          <a
            href='https://wa.me/18019008191/?text=Oi Val!'
            className='social-list__link items-center'
            target='_blank'
          >
            <FaWhatsapp />
            WhatsApp
          </a>
        </li>

        <li className='social-list__item'>
          <a
            href='https://www.instagram.com/valeriamelonascimento_/'
            className='social-list__link items-center'
            target='_blank'
          >
            <FiInstagram />
            Instagram
          </a>
        </li>

        <li className='social-list__item'>
          <a
            href='mailto:valeriamelo1@hotmail.com?Subject=Hi Val, I have a question!'
            className='social-list__link items-center'
            target='_blank'
          >
            <FiMail />
            Email Me
          </a>
        </li>

        {/* <li className='social-list__item'>
            <a
              href='https://www.youtube.com/channel/XYZ/?sub_confirmation=1'
              className='social-list__link items-center'
              target='_blank'
            >
              <FiYoutube />
              <i className='fab fa-youtube-square'></i> Subscribe
            </a>
          </li> */}
      </ul>
    </footer>
  );
};
export default Footer;
