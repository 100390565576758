import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import Carousel from 'react-elastic-carousel';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../styles/index-styles.min.css';

import valeriaMain from '../assets/valeria-main.png';
import valeriaProfile from '../assets/valeria-profile.JPG';
import baptism from '../assets/baptism.JPG';
import firstBirthday from '../assets/first-birthday.JPG';
import engagement from '../assets/engagement.JPG';
import fakeCake from '../assets/fake-cake.JPG';
import valParty53 from '../assets/val-party-53.jpeg';
import valParty17 from '../assets/val-party-17.jpeg';
import valParty32 from '../assets/val-party-32.jpeg';
import valParty77 from '../assets/val-party-77.jpeg';

import Navbar from '../components/Navbar';
import { useAuth } from '../components/Auth';

import api from '../api/config';
import Footer from '../components/Footer';

const IndexPage = () => {
  const [reviews, setReviews] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const handleLogout = () => {
    auth.logout();
    navigate('/');
  };

  useEffect(() => {
    api.get('reviews-approved').then((response) => {
      const reviewData = response.data;
      setReviews(reviewData);
    });
  }, []);

  const handleDelete = async (id) => {
    await api.delete(`reviews-approved/${id}`);
    setReviews(reviews.filter((event) => event._id !== id));
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <>
      <header>
        <div className='logo'>
          <div>
            <Navbar />
            {auth.user && (
              <button className='btn' onClick={handleLogout}>
                Logout
              </button>
            )}
          </div>
        </div>
      </header>

      <section className='intro' id='home'>
        <h1 className='section__title section__title--intro'>
          Hello, I Am <strong>Valeria Nascimento</strong>
        </h1>
        <p className='section__subtitle section__subtitle--intro rounded-xl'>
          Party Decorator
        </p>
        <img src={valeriaMain} alt='Uma foto da Val' className='intro__img' />
      </section>

      <div className='text-center -mt-8 mb-8'>
        <Link to='/create-event' className='btn w-80 rounded-xl animate-bounce'>
          Book Now
        </Link>
      </div>

      <section className='my-services' id='services'>
        <h2 className='section__title section__title--services'>
          What Do I Do
        </h2>
        <div className='services'>
          <div className='service'>
            <h3 className='m-4'>Party Decoration, Organization, and Setup</h3>
            <p>
              No matter the theme you choose or the type of event or party you
              want to promote, I'll do my best to organize, decorate and create
              a positive impact in each individual person who attended your
              party so that can be an unforgettable day for them and for you. We
              can do a birthday parties, baptism, baby shower, wedding, gender
              reveal... you choose.
              {/* Não importa o tema que você escolher ou o tipo de evento que você
              deseja, faço o meu máximo para organizar, decorar e fixar na
              memória de todos da sua festa um lindo tema para que aquele possa
              ser um dia inesquecível para todos. Decoração de festas de
              aniversáro, batismo, chá de bebê, casamento, chá de
              cozinha/panelas, chá revelação... você escolhe. */}
            </p>
          </div>

          <div className='service'>
            <h3 className='m-4'>Fake Cake/Cake Dummies Creation</h3>
            <p>
              Cakes are indispensable in any event or party you can think of.
              The cake is the true attraction of your wedding, birthday, or even
              your kids' baptism. Cake Dummies are made of Fondant icing that
              goes on top of Styrofoam and out of nowhere the become a
              masterpiece. They are so pretty, and you can't almost tell they
              aren't real. If you want, we can make the top layer to be the real
              cake, this way the picture of you cutting the cake won't be
              missed.
              {/* Bolos são
              indispensáveis em qualquer festa que você possa pensar em fazer. O
              bolo da festa é a verdadeira atração do seu casamento, aniversário
              ou até mesmo seu batismo. O bolo falso é feito com pasta americana
              sobre uma base de isopor. Eles ficam lindos e mal da pra dizer que
              eles são que é cenográficos. Se você desejar, podemos fazer o topo
              de verdade assim a foto cortando o bolo não vai faltar. */}
            </p>
          </div>
        </div>
        <div className='service'>
          <h3 className='m-4'>Balloon Decorations and Ornaments</h3>
          <p>
            Regardless the occasion or celebration, party balloons are extremely
            important to beautify your party and celebrate with great style.
            They are great in any event. That's why I'm specialized in in
            balloon ornaments and highly recommend them in your party. The
            benefits are that they are cheap, easy to transport, and they work
            independently of your age.
            {/* Independentemente da ocasião ou celebração, balões de festa são
            estremamente importantes para embelezar sua festa e comemorar com
            grande estilo. Balões de festa são excelentes em qualquer ocasião.
            Por isso me espicializei na ornamentação de balões e recomendo o uso
            deles para a decoração da sua festa. Os principais beneficios são a
            economia, são facilmente transportados e serve para todas as idades. */}
          </p>
        </div>

        <Link to='my-gallery' className='btn rounded-xl'>
          My Projects
        </Link>
      </section>

      <section className='about-me' id='about'>
        <h2 className='section__title section__title--about'>Who Am I</h2>
        <p className='section__subtitle section__subtitle--about rounded-xl'>
          Utah County
        </p>

        <div className='about-me__body'>
          <p>
            My name is Valeria Nascimento, married, mother of four, and I'm
            dedicated to make dreams come true through party decoration since
            1990. I was born in Recife, Brazil and lived in Sao Paulo for 14
            years. By living is 2 of the main cities of Brazil I've always
            sought of ways of specializing myself to become the best party
            decorator in Utah, in Salt Lake City or any place I'm living in. I
            always look for ways to become a better party decorator in Utah and in different
            kinds of parties and events.
            {/* Meu nome é Valéria Nascimento, casada, mãe de quatro filhos,
            dedicada a realizações de sonhos e eventos desde 1990; Eu nasci em
            Recife em 1968 e morando em São Paulo por 14 anos, sempre busquei me
            especializar nos diferentes tipos de festas e decorações. */}
          </p>
          <p>
            Motivated by my passion and enjoyment in party ornaments and
            decorations, my company today offers an excellent service, through
            preparation and set up the party, to taking it all down and leaving
            your location seeing nothing but happy faces.
            {/* Motivada pela paixão e prazer de decorar, minha empresa oferece os
            mais excelentes serviços, desde a montagem e preparação, a
            desmontagem e recolhimento de material, tudo é feito com um trabalho
            excepcional. */}
          </p>
          <p>
            My true pleasure as a party decorator, now in Utah, is to transform physical rooms
            in a perfect scene to your party with kindness and zeal so you can
            have the best experience.
            {/* O prazer da minha empresa é transformar espaços físicos
            em cenários perfeitos para suas festas com zelo e capricho para
            realizar seus eventos. */}
          </p>
        </div>
        <img
          src={valeriaProfile}
          alt='Uma foto da Val'
          className='about-me__img'
        />
      </section>

      <section className='my-work' id='work'>
        <h2 className='section__title section__title--work'>My Projects</h2>
        <p className='section__subtitle section__subtitle--work'>
          A Quick Selection of My Personal Projects.
          <Link to='/my-gallery'> Click here o below to see more</Link>.
        </p>
        <p className='section__subtitle section__subtitle--skills'>
          Skills/Expertise: Party Decorator, Balloon Decor/Ornaments, Color Harmonization, Cake
          Dummies/Fake Cakes.
          {/* Habilidades/Espicializações: Decoração com base em balões,
          harmonização de cores, Bolos Cenográficos. */}
        </p>

        <div className='portfolio'>
          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={baptism}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>
          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={firstBirthday}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={engagement}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={fakeCake}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={valParty53}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={valParty17}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={valParty32}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>

          <div className='portfolio__container'>
            <Link to='/my-gallery' rel='noreferrer' className='portfolio__item'>
              <LazyLoadImage
                src={valParty77}
                alt='Image of one of Val party decoration.'
                className='portfolio__img'
              />
            </Link>
          </div>
        </div>
        {/* <div className='text-center mt-20 -mb-20'>
          <Link
            to='/create-event'
            className='btn w-80 rounded-xl animate-bounce'
          >
            Book Now
          </Link>
        </div> */}
      </section>

      {/* <section className='reviews flex flex-col' id='review'>
        <div>
          <h2 className='section__title--services text-center'>
            What People are Saying About Val
          </h2>
        </div>
        <Carousel
          showArrows={false}
          enableAutoPlay={true}
          breakPoints={breakPoints}
          className='relative z-20 flex items-center self-center w-screen my-0 mx-4'
        >
          {reviews.map((review) => (
            <div
              key={review.id}
              className='flex justify-between flex-col lg:w-[300px] h-[400px] p-6 m-6 rounded-2xl bg-slate-100'
            >
              <div className='flex items-center'>
                <div className='mr-12'>{review.name}</div>
                <ReactStars count={review.rating} size={18} color='#ffd700' />
              </div>
              <div className='text-right'>
                {new Date(review.eventDate).toLocaleDateString()}
              </div>
              <div>{review.testimonial}</div>
              <div className='text-right'>
                {new Date(review.createdAt).toLocaleDateString()}
              </div>
              <div>
                {review.recommend ? (
                  <span>I Recommend!!</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
              {!auth.user && (
                <button onClick={() => handleDelete(review._id)}>Delete</button>
              )}
            </div>
          ))}
        </Carousel>
      </section> */}

      <div className='text-center'>
        <Link to='/write-a-review' className='btn rounded-xl'>
          Write a Review
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
