import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import backIcon from '../assets/back.svg';

const GoBackIcon = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <header className='page-header'>
      <div className='ml-8 hover:opacity-60'>
        <Link to='/' onClick={handleNavigate}>
          <img src={backIcon} alt='Go back Icon' />
        </Link>
      </div>
    </header>
  );
};

export default GoBackIcon;
