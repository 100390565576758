import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { FaRegWindowClose } from 'react-icons/fa';
import { useAuth } from './Auth';

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  const auth = useAuth();

  return (
    <>
      <button className='mobile-menu-icon' onClick={() => setOpen(!isOpen)}>
        {isOpen ? <FaRegWindowClose /> : <FaBars />}
      </button>
      <div
        className={
          isOpen ? 'inset-0 fixed z-10 bg-black opacity-50' : 'opacity-0'
        }
        onClick={() => setOpen(false)}
      ></div>
      <nav className={isOpen ? 'nav-opened' : 'nav'}>
        <ul className={isOpen ? 'nav__list' : 'nav_links'}>
          <li className='nav__item'>
            <NavLink
              className='hidden lg:flex text-3xl lg:ml-4 bg-white text-pinkish p-4 rounded-xl animate-pulse'
              to='/create-event'
            >
              Book Now!
            </NavLink>
          </li>
          <li className='nav__item'>
            <NavLink
              to='/my-gallery'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              My Gallery
            </NavLink>
          </li>
          <li className='nav__item'>
            <Link
              to='home'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              Home
            </Link>
          </li>

          <li className='nav__item'>
            <Link
              to='services'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              My Services
            </Link>
          </li>

          <li className='nav__item'>
            <Link
              to='about'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              About Me
            </Link>
          </li>

          <li className='nav__item'>
            <Link
              to='work'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              My Projects
            </Link>
          </li>

          <li className='nav__item'>
            <Link
              to='footer'
              smooth={true}
              duration={100}
              className='nav__link'
              onClick={() => setOpen(false)}
            >
              Let's Talk
            </Link>
          </li>
          {auth.user && (
            <li className='nav__item'>
              <NavLink to='/my-events' className='nav__link'>
                My Events
              </NavLink>
            </li>
          )}
          {!auth.user && (
            <li className='nav__item'>
              <NavLink className='text-base ml-24' to='/login'>
                Login
              </NavLink>
            </li>
          )}
          <li className='nav__item'>
            <NavLink
              className='flex lg:hidden text-3xl lg:ml-12 bg-white text-pinkish p-4 rounded-xl animate-pulse'
              to='/create-event'
            >
              Book Now!
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
