import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';


import Footer from '../components/Footer';
import Header from '../components/Header';


const ThankYouPage = () => {
  return (
    <>
      <Helmet>
        <title>Thank you - Party Decorator - Val Nascimento</title>
      </Helmet>
      <div className='events__component'>
        <Header>
          <Link to='/create-event'>
            <h1 className='mr-16 my-2 text-2xl'>
              Your Event Was Booked! We will be in touch soon.
            </h1>
          </Link>
        </Header>
        {/* <nav className='flex items-center justify-between bg-lighterPinkish'>
          <GoBackIcon />
          
          <div></div>
        </nav> */}
      </div>
      <div className='flex flex-col items-center justify-center h-screen -my-20'>
        <h1>Thank You!!</h1>
        <p>You don't have to do anything right now.</p>
        <p>
          I'll reach out to you so we can get started with this amazing party!
        </p>
        <p className='mt-12'>Still have questions? No Problem at all!</p>
        <div className='flex justify-center items-center'>
          <a
            href='https://wa.me/18019008191/?text=Oi Val!'
            className='social-list__link'
            target='_blank'
          >
            <div className='text-2xl text-whatsAppGreen flex items-center justify-center'>
              {'---->'} <FaWhatsapp className='text-3xl ml-4' />
              <span className='mx-4'>WhatsApp</span> {'<----'}
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ThankYouPage;
