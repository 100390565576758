import React from 'react';
import GoBackIcon from '../components/GoBackIcon';

const Header = ({ children }) => {
  return (
    <>
      <nav className='flex items-center justify-around bg-lighterPinkish'>
        <GoBackIcon to='/' />
        {children}
        <div></div>
      </nav>
    </>
  );
};
export default Header;
