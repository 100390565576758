import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import api from '../api/config';
import { useAuth } from '../components/Auth';
import GoBackIcon from '../components/GoBackIcon.js';
import Header from '../components/Header';

const MyEventsPage = () => {
  const [events, setEvents] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate('/');
  };

  useEffect(() => {
    api.get('events/upcoming').then((response) => {
      setEvents(response.data);
    });
  }, []);

  const handleRefresh = () => {
    api.get('events/upcoming').then((response) => {
      setEvents(response.data);
    });
  };

  // const handleDelete = async (id) => {
  //   await api.delete(`events/${id}`);
  //   setEvents(events.filter((event) => event._id !== id));
  // };

  return (
    <>
      {!auth.user && (
        <div className='flex justify-around flex-row-reverse'>
          <Link className='text-base ml-24' to='/login'>
            Login
          </Link>{' '}
          <Link to='/'>Back</Link>
        </div>
      )}
      {auth.user && (
        <div className='events__component'>
          <Header>
            <h1 className='mx-2 my-2'>Welcome {auth.user}</h1>
            {/* <button onClick={handleRefresh}>Refresh</button> */}
            <button className='mx-2 my-2' onClick={handleLogout}>
              Logout
            </button>
          </Header>
          {/* <nav className='flex justify-between items-center bg-lighterPinkish'>
            <GoBackIcon className='mx-2 my-2' to='/' />
            
          </nav> */}
          <div className='event__list flex flex-wrap'>
            {events.map((event) => (
              <ul
                key={event._id}
                className='border-4 border-pinkish w-screen lg:w-4/12 lg:m-12'
              >
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Titulo: {event.title}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Tema: {event.theme}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Nome: {event.firstName}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Sobrenome: {event.lastName}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Telefone: {event.phone}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Email: {event.email}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Começa as: {new Date(event.start).toLocaleString()}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Termina as: {new Date(event.end).toLocaleString()}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Endereço: {event.address}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Cidade: {event.city}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Zip: {event.zipcode}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Data de Aniversário:
                  {new Date(event.dateOfBirth).toLocaleDateString()}
                </li>
                <li className='m-1 p-1 border-2 border-lightPinkish'>
                  Criado as: {new Date(event.createdAt).toLocaleString()}
                </li>
                <div className='flex items-center justify-around'>
                  <Link
                    to={{ pathname: `edit-event/${event._id}` }}
                    className='btn'
                  >
                    Edit
                  </Link>
                  {/* <button
                    className='py-4 px-14 text-[0.8rem] tracking-wider bg-pagelightPinkish text-rockBlue font-bold'
                    onClick={() => handleDelete(event._id)}
                  >
                    DELETE
                  </button> */}
                </div>
              </ul>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MyEventsPage;
