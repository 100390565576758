import React, { useState } from 'react';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { XIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';

// import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../api/config';

const CreateEventModal = () => {
  const [modal, setModal] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [formData, setFormData] = useState({
    title: '',
    theme: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    zipcode: '',
  });

  const handleModal = () => {
    setModal(!modal);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'phone') {
      setFormData({ ...formData, [name]: +value });
    } else if (name === 'zipcode') {
      setFormData({ ...formData, [name]: +value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const valid = (current) => {
    var valid = current.day() !== 0;

    return valid;
  };

  const refreshPage = () => {
    window.location.reload(true);
  };

  const onChange = async (data) => {
    const {
      title,
      theme,
      firstName,
      lastName,
      phone,
      email,
      address,
      city,
      zipcode,
    } = data;

    const eventData = {
      title,
      theme,
      firstName,
      lastName,
      phone,
      email,
      dateOfBirth,
      address,
      city,
      zipcode,
      start,
      end,
    };

    console.log('Event Data', eventData);

    await api.post('events', eventData);

    handleModal(false);

    reset();

    navigate('/create-event/thank-you');
  };

  return (
    <div className='text-center'>
      <div className='hidden lg:flex lg:justify-around'>
        <button
          onClick={handleModal}
          className={
            'btn bg-pinkish text-white rounded-md font-medium lg:text-3xl'
          }
        >
          New Event
        </button>

        {/* <button
          onClick={refreshPage}
          className='bg-calendarGray text-white rounded-full text-xl p-4 absolute right-8'
        >
          Refresh
        </button> */}
      </div>

      {modal && (
        <div className=''>
          <form
            onSubmit={handleSubmit(onChange)}
            className='flex flex-col inset-0 fixed z-10 text-left overflow-scroll'
          >
            <div
              className='hidden lg:block inset-0 fixed z-10 bg-black opacity-30'
              onClick={handleModal}
            ></div>
            <div className='flex flex-col lg:flex-row absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 leading-normal bg-white py-4 px-7 lg:pr-7 lg:border border-pinkish h-full lg:h-auto lg:w-900 z-50 w-full'>
              <div className='lg:w-3/6 flex flex-col md:justify-center md:items-center'>
                <h2 className='hidden md:flex text-4xl justify-center text-pinkish w-full'>
                  Create a New Event:
                </h2>
                <div className='flex flex-col w-full lg:items-center'>
                  <label className='flex flex-col mt-2'>
                    <span className='text-pinkish'>Title</span>
                    <input
                      autoComplete='off'
                      placeholder='Title'
                      name='title'
                      className={`outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('title', { required: 'Title is Required' })}
                    />
                    {errors.title && <small>{errors.title.message}</small>}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Theme</span>
                    <input
                      autoComplete='off'
                      placeholder='Theme'
                      name='theme'
                      className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('theme', { required: 'Theme is Required' })}
                    />
                    {errors.theme && <small>{errors.theme.message}</small>}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>First Name</span>
                    <input
                      autoComplete='off'
                      placeholder='First Name'
                      name='firstName'
                      className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('firstName', {
                        required: 'First Name is Required',
                      })}
                    />
                    {errors.firstName && (
                      <small>{errors.firstName.message}</small>
                    )}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Last Name</span>
                    <input
                      autoComplete='off'
                      placeholder='Last Name'
                      name='lastName'
                      className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('lastName', {
                        required: 'Last Name is Required',
                      })}
                    />
                    {errors.lastName && (
                      <small>{errors.lastName.message}</small>
                    )}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Phone</span>
                    <input
                      type='tel'
                      autoComplete='off'
                      placeholder='Phone'
                      name='phone'
                      minLength={10}
                      maxLength={12}
                      className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('phone', {
                        required: 'Phone is Required',
                        pattern: {
                          value:
                            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          message: 'Invalid Phone Number',
                          maxLength: 12,
                          valueAsNumber: true,
                        },
                      })}
                    />
                    {errors.phone && <small>{errors.phone.message}</small>}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Email</span>
                    <input
                      autoComplete='off'
                      placeholder='Email'
                      name='email'
                      className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.title && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid Email Address',
                        },
                      })}
                    />
                    {errors.email && <small>{errors.email.message}</small>}
                  </label>
                </div>
              </div>
              <div className='flex flex-col justify-center w-full lg:w-3/6'>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Date Of Birth</span>
                  <DatePicker
                    name='dateOfBirth'
                    selected={dateOfBirth}
                    placeholderText='Birthday'
                    showYearDropdown
                    showMonthDropdown
                    className={`outline-none w-full lg:w-100 border-pinkish border lg:h-10 font-bold lg:my-3 px-2
                    }`}
                    onChange={(date) => setDateOfBirth(date)}
                  />
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Address</span>
                  <input
                    autoComplete='off'
                    placeholder='Address'
                    name='address'
                    className={`outline-none lg:w-100 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.address && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('address', {
                      required: 'Address is Required',
                    })}
                  />
                  {errors.address && <small>{errors.address.message}</small>}
                </label>
                <div className='flex flex-col lg:flex-row w-full'>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>City</span>
                    <input
                      autoComplete='off'
                      placeholder='City'
                      name='city'
                      className={`outline-none lg:w-50 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.city && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('city', { required: 'City is Required' })}
                    />
                    {errors.city && <small>{errors.city.message}</small>}
                  </label>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Zipcode</span>
                    <input
                      autoComplete='off'
                      placeholder='Zipcode'
                      name='zipcode'
                      minLength={5}
                      maxLength={5}
                      className={`outline-none lg:w-50 lg:ml-4 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                        errors.zipcode && 'border-red-600'
                      }`}
                      onChange={handleInputChange}
                      {...register('zipcode', {
                        required: 'Zipcode is Required',
                        pattern: {
                          value: /^\d*$/,
                          message: 'You typed a letter',
                          minLength: 5,
                          maxLength: 5,
                          valueAsNumber: true,
                        },
                      })}
                    />
                    {errors.zipcode && <small>{errors.zipcode.message}</small>}
                  </label>
                </div>
                <div className='flex flex-col lg:flex-row w-full'>
                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Date and Start Time</span>
                    <Datetime
                      initialValue={new Date()}
                      utc={false}
                      isValidDate={valid}
                      className='outline-none lg:w-50 border-pinkish border lg:h-10 font-bold lg:my-3 text-inherit text-center justify-center flex text-defaultGray'
                      onChange={(date) => setStart(date)}
                    />
                  </label>

                  <label className='flex flex-col mt-4'>
                    <span className='text-pinkish'>Date and End Time</span>
                    <Datetime
                      initialValue={new Date()}
                      utc={false}
                      isValidDate={valid}
                      className='outline-none lg:w-50 lg:ml-4 border-pinkish border lg:h-10 font-bold lg:my-3 text-inherit text-center justify-center flex text-defaultGray'
                      onChange={(date) => setEnd(date)}
                    />
                  </label>
                </div>
                <div className='flex justify-center flex-row w-full'>
                  <button
                    onClick={handleModal}
                    className={
                      'py-4 w-48 bg-pagelightPinkish text-rockBlue my-4 mr-2 font-medium text-lg'
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className={
                      'py-1 w-48 bg-pinkish text-white my-4 mr-2 font-medium text-lg'
                    }
                  >
                    Create Event
                  </button>
                </div>
              </div>
              <div className='absolute top-0 right-0 mr-2 pt-2'>
                <button
                  type='button'
                  className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                  onClick={handleModal}
                >
                  <XIcon className='h-6 w-6 text-black' aria-hidden='true' />
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className='flex justify-around absolute bottom-48 right-0 lg:hidden'>
        <button
          onClick={handleModal}
          className={
            'btn bg-pinkish text-white rounded-md font-medium lg:text-3xl'
          }
        >
          New Event
        </button>
      </div>
    </div>
  );
};

export default CreateEventModal;
