import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';

import 'react-datepicker/dist/react-datepicker.css';
// import Rating from '../components/StarRating';

import api from '../api/config';
import Footer from '../components/Footer';
import Header from '../components/Header';

const ReviewsPage = () => {
  const [name, setName] = useState('');
  const [eventTheme, setEventTheme] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [rating, setRating] = useState(0);
  const [testimonial, setTestimonial] = useState('');
  const [recommend, setRecommend] = useState(true);

  const navigate = useNavigate();

  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRating(newRating);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCheckbox = (e) => {
    setRecommend(!recommend);
    console.log(e.target.value);
  };

  const onChange = async () => {
    // const { name, eventTheme, eventDate, rating, testimonial } = data;

    const reviewData = {
      name,
      eventTheme,
      eventDate,
      rating,
      testimonial,
      recommend,
    };

    console.log('Review Data', reviewData);

    await api.post('reviews', reviewData);

    reset();

    navigate('/write-a-review/thank-you');
  };

  return (
    <>
      <Header>
        <h1 className='mr-28 my-2'>Your Opinion is Important!</h1>
      </Header>
      <form
        onSubmit={handleSubmit(onChange)}
        className='flex flex-col justify-center lg:min-h-screen lg:w-100 m-auto text-2xl lg:text-base'
      >
        <div className='bg-lighterPinkish'>
          <label className='flex flex-col mt-2 px-6'>
            <span className='text-pinkish'>Name</span>
            <input
              autoComplete='off'
              placeholder='Name'
              name='name'
              value={name}
              className={`outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2 ${
                errors.name && 'border-red-600'
              }`}
              onChange={(e) => setName(e.target.value)}
              // {...register('name', { required: 'Name is Required' })}
            />
            {errors.name && <small>{errors.name.message}</small>}
          </label>
          <label className='flex flex-col mt-2 px-6'>
            <span className='text-pinkish'>Event Theme</span>
            <input
              autoComplete='off'
              placeholder='Event Theme'
              name='eventTheme'
              value={eventTheme}
              className={`outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2 ${
                errors.eventTheme && 'border-red-600'
              }`}
              onChange={(e) => setEventTheme(e.target.value)}
              // {...register('eventTheme', { required: 'Event Theme is Required' })}
            />
            {errors.eventTheme && <small>{errors.eventTheme.message}</small>}
          </label>
          <label className='flex flex-col mt-2 px-6'>
            <span className='text-pinkish'>Party Date</span>
            <DatePicker
              autoComplete='off'
              name='eventDate'
              selected={eventDate}
              value={eventDate}
              placeholderText='Party Date'
              showYearDropdown
              scrollableYearDropdown
              showMonthDropdown
              className={`outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2
                    }`}
              onChange={(date) => setEventDate(date)}
            />
          </label>
          <label className='flex flex-col mt-2 px-6'>
            <span className='text-pinkish'>Rating</span>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              value={rating}
              size={37}
              className='outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2' //${errors.rating && 'border-red-600'}`}
              // {...register('rating', { required: 'Rating is Required' })}
            />
            {/* {errors.rating && <small>{errors.rating.message}</small>} */}
          </label>
          <label className='flex flex-col mt-2 px-6'>
            <span className='text-pinkish'>Tell us about your experience</span>
            <textarea
              maxLength={256}
              autoComplete='off'
              placeholder='Tell us about your experience'
              name='testimonial'
              value={testimonial}
              className={`outline-none w-full lg:w-96 border-pinkish border lg:h-36 font-bold lg:my-1 px-2 ${
                errors.testimonial && 'border-red-600'
              }`}
              onChange={(e) => setTestimonial(e.target.value)}
              // {...register('testimonial', { required: 'This is Required' })}
            />
            {errors.testimonial && <small>{errors.testimonial.message}</small>}
          </label>
          <label className='flex items-center mt-2 px-6'>
            <span className='text-pinkish'>Do you Recommend?</span>
            <input
              type='checkbox'
              autoComplete='off'
              placeholder='Do you Recommend?'
              name='recommend'
              value={recommend}
              className={`outline-none w-6 h-6 lg:w-5 lg:h-10 mx-4 border-pinkish border font-bold lg:my-1 px-2 ${
                errors.recommend && 'border-red-600'
              }`}
              defaultChecked={true}
              onClick={handleCheckbox}
              // {...register('recommend', { required: 'Do you Recommend? is Required' })}
            />
            {errors.recommend && <small>{errors.recommend.message}</small>}
          </label>
          <div className='text-center'>
            <button
              className={
                'py-1 w-48 bg-pinkish text-white my-4 mr-2 font-medium text-lg rounded-xl'
              }
            >
              Submit Review
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
};
export default ReviewsPage;
