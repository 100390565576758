import { useState } from 'react';
import { useAuth } from '../components/Auth';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const LoginPage = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || '/my-events';

  const handleLogin = () => {
    if (
      user === process.env.REACT_APP_PROTECTED_USERNAME &&
      password === process.env.REACT_APP_PROTECTED_PASSWORD
    ) {
      auth.login(user);
      navigate(redirectPath, { replace: true });
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <div className='flex flex-col mx-auto m-56 items-center w-80 bg-lightPinkish'>
        <label>Username:</label>
        <input
          type='text'
          onChange={(e) => {
            setUser(e.target.value);
          }}
        />
        <label>Password:</label>
        <input
          type='password'
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button onClick={handleLogin} className='btn'>
          Login
        </button>
        <Link to='/'>Back</Link>
      </div>
    </>
  );
};

export default LoginPage;
