import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import GoBackIcon from '../components/GoBackIcon';
import Footer from '../components/Footer';

const ThankYouPage = () => {
  return (
    <>
      <Helmet>
        <title>Thank you - Party Decorator - Val Nascimento</title>
      </Helmet>
      <div className='events__component'>
        <nav className='flex items-center justify-between bg-lighterPinkish'>
          <GoBackIcon />
          <Link to='/create-event'>
            <h1 className='mr-16 my-2 text-2xl'>
              Your Review Was Submitted
            </h1>
          </Link>
          <div></div>
        </nav>
      </div>
      <div className='flex flex-col items-center justify-center h-screen -my-20'>
        <h1>Your Review is under Analysis</h1>
        <p className='mt-12'>Still have questions? No Problem at all!</p>
        <div className='flex justify-center items-center'>
          <a
            href='https://wa.me/18019008191/?text=Oi Val!'
            className='social-list__link'
            target='_blank'
          >
            <div className='text-2xl text-whatsAppGreen flex items-center justify-center'>
              {'---->'} <FaWhatsapp className='text-3xl ml-4' />
              <span className='mx-4'>WhatsApp</span> {'<----'}
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ThankYouPage;
