import React, { useState, useEffect } from 'react';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { useNavigate, Link, useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../api/config';
import { useAuth } from '../components/Auth';
import GoBackIcon from '../components/GoBackIcon.js';
import Header from '../components/Header';

const CreateEventModal = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const { id } = useParams();

  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate('/');
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [formData, setFormData] = useState({
    title: '',
    theme: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    zipcode: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'phone') {
      setFormData({ ...formData, [name]: +value });
    } else if (name === 'zipcode') {
      setFormData({ ...formData, [name]: +value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const valid = (current) => {
    var valid = current.day() !== 0;

    return valid;
  };

  const onChange = async (data) => {
    const {
      title,
      theme,
      firstName,
      lastName,
      phone,
      email,
      address,
      city,
      zipcode,
    } = data;

    const eventData = {
      title,
      theme,
      firstName,
      lastName,
      phone,
      email,
      dateOfBirth,
      address,
      city,
      zipcode,
      start,
      end,
    };

    console.log('Event Data', eventData);

    const patch = await api
      .patch(`events/${id}`, eventData)
      .then((response) => {
        reset(response.data);
        navigate('/my-events');
      });

    reset();
  };

  useEffect(() => {
    api.get(`events/${id}`).then((response) => {
      reset(response.data);
    });
  }, []);

  return (
    <div className='text-center'>
      {auth.user && (
        <Header>
          <h1 className='mr-16 my-2 text-2xl'>Welcome {auth.user}</h1>
          <button className='mx-2 my-2' onClick={handleLogout}>
            Logout
          </button>
        </Header>
      )}
      <div className=''>
        <form
          onSubmit={handleSubmit(onChange)}
          className='flex flex-col z-10 text-left'
        >
          {/* <div
              className='hidden lg:block inset-0 fixed z-10 bg-black opacity-30'
            //
            ></div> */}
          <div className='flex flex-col lg:flex-row absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 leading-normal bg-white py-4 px-7 lg:pr-7 lg:border border-pinkish h-full lg:h-auto lg:w-900 z-50 w-full'>
            <div className='lg:w-3/6 flex flex-col md:justify-center md:items-center'>
              <h2 className='hidden md:flex text-4xl justify-center text-pinkish w-full'>
                Create a New Event:
              </h2>
              <div className='flex flex-col w-full lg:items-center'>
                <label className='flex flex-col mt-2'>
                  <span className='text-pinkish'>Title</span>
                  <input
                    autoComplete='off'
                    placeholder='Title'
                    name='title'
                    className={`outline-none w-full lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-1 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('title', { required: 'Title is Required' })}
                  />
                  {errors.title && <small>{errors.title.message}</small>}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Theme</span>
                  <input
                    autoComplete='off'
                    placeholder='Theme'
                    name='theme'
                    className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('theme', { required: 'Theme is Required' })}
                  />
                  {errors.theme && <small>{errors.theme.message}</small>}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>First Name</span>
                  <input
                    autoComplete='off'
                    placeholder='First Name'
                    name='firstName'
                    className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('firstName', {
                      required: 'First Name is Required',
                    })}
                  />
                  {errors.firstName && (
                    <small>{errors.firstName.message}</small>
                  )}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Last Name</span>
                  <input
                    autoComplete='off'
                    placeholder='Last Name'
                    name='lastName'
                    className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('lastName', {
                      required: 'Last Name is Required',
                    })}
                  />
                  {errors.lastName && <small>{errors.lastName.message}</small>}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Phone</span>
                  <input
                    type='tel'
                    autoComplete='off'
                    placeholder='Phone'
                    name='phone'
                    minLength={10}
                    maxLength={12}
                    className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('phone', {
                      required: 'Phone is Required',
                      pattern: {
                        value:
                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                        message: 'Invalid Phone Number',
                        maxLength: 12,
                        valueAsNumber: true,
                      },
                    })}
                  />
                  {errors.phone && <small>{errors.phone.message}</small>}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Email</span>
                  <input
                    autoComplete='off'
                    placeholder='Email'
                    name='email'
                    className={`outline-none lg:w-96 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.title && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('email', {
                      required: 'Email is Required',
                      pattern: {
                        value: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid Email Address',
                      },
                    })}
                  />
                  {errors.email && <small>{errors.email.message}</small>}
                </label>
              </div>
            </div>
            <div className='flex flex-col justify-center w-full lg:w-3/6'>
              <label className='flex flex-col mt-4'>
                <span className='text-pinkish'>Date Of Birth</span>
                <DatePicker
                  name='dateOfBirth'
                  selected={dateOfBirth}
                  placeholderText='Birthday'
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  className={`outline-none lg:w-100 border-pinkish border lg:h-10 font-bold lg:my-3 px-2
                    }`}
                  onChange={(date) => setDateOfBirth(date)}
                />
              </label>
              <label className='flex flex-col mt-4'>
                <span className='text-pinkish'>Address</span>
                <input
                  autoComplete='off'
                  placeholder='Address'
                  name='address'
                  className={`outline-none lg:w-100 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                    errors.address && 'border-red-600'
                  }`}
                  onChange={handleInputChange}
                  {...register('address', {
                    required: 'Address is Required',
                  })}
                />
                {errors.address && <small>{errors.address.message}</small>}
              </label>
              <div className='flex flex-col lg:flex-row w-full'>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>City</span>
                  <input
                    autoComplete='off'
                    placeholder='City'
                    name='city'
                    className={`outline-none lg:w-50 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.city && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('city', { required: 'City is Required' })}
                  />
                  {errors.city && <small>{errors.city.message}</small>}
                </label>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Zipcode</span>
                  <input
                    autoComplete='off'
                    placeholder='Zipcode'
                    name='zipcode'
                    minLength={5}
                    maxLength={5}
                    className={`outline-none lg:w-50 lg:ml-4 border-pinkish border lg:h-10 font-bold lg:my-3 px-2 ${
                      errors.zipcode && 'border-red-600'
                    }`}
                    onChange={handleInputChange}
                    {...register('zipcode', {
                      required: 'Zipcode is Required',
                      pattern: {
                        value: /^\d*$/,
                        message: 'You typed a letter',
                        minLength: 5,
                        maxLength: 5,
                        valueAsNumber: true,
                      },
                    })}
                  />
                  {errors.zipcode && <small>{errors.zipcode.message}</small>}
                </label>
              </div>
              <div className='flex flex-col lg:flex-row w-full'>
                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Date and Start Time</span>
                  <Datetime
                    initialValue={new Date()}
                    utc={false}
                    isValidDate={valid}
                    className='outline-none lg:w-50 border-pinkish border lg:h-10 font-bold lg:my-3 text-inherit text-center justify-center flex text-defaultGray'
                    onChange={(date) => setStart(date)}
                  />
                </label>

                <label className='flex flex-col mt-4'>
                  <span className='text-pinkish'>Date and End Time</span>
                  <Datetime
                    initialValue={new Date()}
                    utc={false}
                    isValidDate={valid}
                    className='outline-none lg:w-50 lg:ml-4 border-pinkish border lg:h-10 font-bold lg:my-3 text-inherit text-center justify-center flex text-defaultGray'
                    onChange={(date) => setEnd(date)}
                  />
                </label>
              </div>
              <div className='flex justify-center flex-row w-full'>
                <Link
                  to='/my-events'
                  className={
                    'py-4 w-48 text-center bg-pagelightPinkish text-rockBlue my-4 mr-2 font-medium text-lg'
                  }
                >
                  Cancel
                </Link>
                <button
                  className={
                    'py-1 w-48 bg-pinkish text-white my-4 mr-2 font-medium text-lg'
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventModal;
