import React, { useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';

import CreateEventModal from './CreateEventModal';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);

  async function handleEventAdd(data) {
    console.log('Line 19 log: ', data.event);
    await axios.post(
      'https://valeria-api.onrender.com/events',
      data.event
    );
  }

  async function handleDateSet(data) {
    const response = await axios.get(
      'https://valeria-api.onrender.com/events'
    );
    setEvents(response.data);
  }

  return (
    <section>
      <CreateEventModal />

      <div className='relative z-0'>
        {/* <button onClick={handleDateSet}>refresh</button> */}
        <FullCalendar
          ref={calendarRef}
          events={events}
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          eventAdd={(event) => handleEventAdd(event)}
          datesSet={(date) => handleDateSet(date)}
        />
      </div>
    </section>
  );
};

export default Calendar;
