import React from 'react';
import Calendar from '../components/Calendar';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';

const CreateEventPage = () => {
  return (
    <>
      <div className='events__component'>
        <Helmet>
          <title>Create Event - Party Decorator - Val Nascimento</title>
        </Helmet>
        <Header>
          <h1 className='mr-16 my-2 text-2xl'>
            Book your Event and We'll be in touch soon
          </h1>
        </Header>
      </div>
      <Calendar />
      <Footer />
    </>
  );
};

export default CreateEventPage;
