import React from 'react';
import { Route, Routes } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import CreateEventPage from './pages/CreateEventPage';
import MyEventsPage from './pages/MyEventsPage';
import EditEventPage from './pages/EditEventPage';
import NotFoundPage from './pages/NotFoundPage';
import AuthProvider from './components/Auth';
import LoginPage from './pages/LoginPage';
import RequireAuth from './components/RequireAuth';
import ThankYouPage from './pages/ThankYouPage';
import ReviewsPage from './pages/ReviewsPage';
import ReviewsThankYouPage from './pages/ReviewsThankYouPage';
// import PartyGalleryPage from './pages/PartyGalleryPage';
const LazyGalleryPage = React.lazy(() => import('./pages/PartyGalleryPage'));

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='/create-event' element={<CreateEventPage />} />
        <Route path='/create-event/thank-you' element={<ThankYouPage />} />
        <Route path='/write-a-review' element={<ReviewsPage />} />
        <Route path='/write-a-review/thank-you' element={<ReviewsThankYouPage />} />
        <Route
          path='/my-events'
          element={
            <RequireAuth>
              <MyEventsPage />
            </RequireAuth>
          }
        />
        <Route
          path='/my-events/edit-event/:id'
          element={
            <RequireAuth>
              <EditEventPage />
            </RequireAuth>
          }
        />
        <Route
          path='/my-gallery'
          element={
            <React.Suspense fallback='Book your Party Now...'>
              <LazyGalleryPage />
            </React.Suspense>
          }
        />
        {/* <Route path='/my-gallery' element={<PartyGalleryPage />} /> */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
